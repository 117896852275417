<template>
  <div>
    <el-dialog
        v-model="showDia"
        :title="titleStr"
        width="480px"
        class="abcCheck"
        align-center
      >
      <div class="content">
        {{contentStr}}
      </div>
      <template #footer>
          <el-button @click="showDia = false">取消</el-button>
          <el-button class="main" type="primary" @click="handleOk">
            确认</el-button>
      </template>
    </el-dialog>
  </div>
</template>


<script>
import {ref,getCurrentInstance ,watch } from "vue";
import {  Search,Plus } from "@element-plus/icons-vue";
export default {
  components:{
    Search,
    Plus
  },
  props: {
    title:String,
    content:String,
    show:Boolean,
  },
  setup(props,{emit}) {
    const { proxy } = getCurrentInstance()
    const titleStr = ref(props.title)
    const contentStr = ref(props.content)
    const showDia = ref(props.show)
    const handleOk = ()=>{
      emit('ok',{})
    }
    watch(
      () => props.show,
      (newValue, oldValue) =>{
      showDia.value = newValue
    })
    watch(
      () => props.title,
      (newValue, oldValue) =>{
      titleStr.value = newValue
    })
    watch(
      () => props.content,
      (newValue, oldValue) =>{
      contentStr.value = newValue
    })
    watch(() => showDia.value,(newValue)=>{
      emit('close',newValue)
    })
    return {
      titleStr,
      contentStr,
      showDia,
      handleOk
    };
  },
};
</script>
<style lang="scss">
  .abcCheck{
    :deep(.el-dialog__header){
      font-size: 16px;
    }
    .content{
      padding: 30px 0 20px;
      text-align: center;
      font-size: 16px;
      font-weight: 600;
    }
  }
</style>
